<template>
    <transition name="fade" mode="out-in">
        <div class="loading-bg" v-if="isLoading">
            <div class="loader loader--style6">
                <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="40px"
                    height="50px"
                    viewBox="0 0 24 30"
                    style="enable-background: new 0 0 50 50"
                    xml:space="preserve"
                >
                    <rect x="20" y="13" width="4" height="5" fill="#333">
                        <animate
                            attributeName="height"
                            attributeType="XML"
                            values="5;21;5"
                            begin="0s"
                            dur="0.6s"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="y"
                            attributeType="XML"
                            values="13; 5; 13"
                            begin="0s"
                            dur="0.6s"
                            repeatCount="indefinite"
                        />
                    </rect>
                    <rect x="10" y="13" width="4" height="5" fill="#333">
                        <animate
                            attributeName="height"
                            attributeType="XML"
                            values="5;21;5"
                            begin="0.15s"
                            dur="0.6s"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="y"
                            attributeType="XML"
                            values="13; 5; 13"
                            begin="0.15s"
                            dur="0.6s"
                            repeatCount="indefinite"
                        />
                    </rect>
                    <rect x="0" y="13" width="4" height="5" fill="#333">
                        <animate
                            attributeName="height"
                            attributeType="XML"
                            values="5;21;5"
                            begin="0.3s"
                            dur="0.6s"
                            repeatCount="indefinite"
                        />
                        <animate
                            attributeName="y"
                            attributeType="XML"
                            values="13; 5; 13"
                            begin="0.3s"
                            dur="0.6s"
                            repeatCount="indefinite"
                        />
                    </rect>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Loading',
    computed: {
        isLoading: function () {
            return this.$store.state.isLoading
        }
    },
    watch: {
        isLoading: function () {
            const body = document.querySelector('body')
            if (this.isLoading) {
                this.$addAllClass(body, 'not-scroll')
            } else {
                this.$removeAllClass(body, 'not-scroll')
            }
        }
    },
    created() {
        if (this.isLoading) {
            this.$addAllClass(document.querySelector('body'), 'not-scroll')
        }
    },
    unmounted() {
        this.$removeAllClass(document.querySelector('body'), 'not-scroll')
    }
}
</script>

<style lang="scss" scoped>
.loading-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    &.hide {
        display: none;
    }
}

.loader {
    margin: 0 0 2em;
    height: 100px;
    width: 20%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    transform: rotate(180deg);
}

/*
Set the color of the icon
*/
svg path,
svg rect {
    fill: $primary-red;
}

.bar1 {
    fill: $primary-red;
}

.bar2 {
    fill: $primary-yellow;
}

.bar3 {
    fill: url(#gradient1);
}

.bar4 {
    fill: url(#gradient1);
}

.bar5 {
    fill: url(#gradient1);
}
</style>

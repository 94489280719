<template>
    <Header />
    <div class="outside-container">
        <div class="container">
            <div class="main-content">
                <router-view />
            </div>
        </div>
    </div>
    <Loading />
</template>

<script>
import Header from '@/containers/Header.vue'
import Loading from '@/containers/Loading.vue'

export default {
    name: 'WideSizeLayout',
    components: {
        Header,
        Loading
    },
    created() {
        this.$hideLoading()
    }
}
</script>

<style lang="scss" scoped>
.main-content {
    width: $primary-central-width;
    background-color: $primary-grey;
    // overflow: scroll;
    @media screen and (min-width: 1281px) {
        width: $primary-central-width;
    }
    @media screen and (min-width: 992px) and (max-width: 1280px) {
        width: $secondary-central-width;
    }
    @media screen and (min-width: 800px) and (max-width: 991px) {
        width: $third-central-width;
    }
    @media screen and (min-width: 576px) and (max-width: 799px) {
        width: $fourth-central-width;
    }
    @media screen and (min-width: 0px) and (max-width: 575px) {
        width: $fifth-central-width;
    }
}
</style>
